import React, { useEffect, useState } from 'react';
import { post } from '../services/apiService';
import { Link } from 'react-router-dom';

function Orders(){

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, [])

  const fetchOrders = async () => {
    try{
      const response = await post('/account/orders');
      setOrders(response);
    }catch(e){
      console.log("Une erreur est survenue");
    }
  }
  return (
    <div className="mx-auto max-w-7xl">
      <div className="mt-10 m-3">
        <div>Vos commandes</div>
        <div>
          {orders.map((order, index) => (
            <div className="shadow-lg rounded mb-6" key={index}>
              <div className="bg-gray-50 py-3 px-6 shadow mb-2">
                <div className="flex">
                  <div className="">
                    <div className="uppercase text-xs">Commande du</div>
                    <div>{(new Date(order.created_at)).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })}</div>
                  </div>
                  <div className="ml-3">
                    <div className="uppercase text-xs">Total</div>
                    <div>{order.total} cfa</div>
                  </div>
                </div>
              </div>
              <div>
                {order.orderlines.map(ol => (
                  <div className="flex items-center">
                    <div className="w-40 h-40">
                      <img src={ol.product.medias[0].url} />
                    </div>
                    <div className="text-blue-600">
                      <Link to={`/product/${ol.product.slug}`} target="_blank">
                        {ol.product.name}
                      </Link>
                    </div>
                  </div>
                ))}
                <div></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Orders;
