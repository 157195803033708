import React, { useContext, useState } from 'react';
import Modal from './Modal';
import { Link } from 'react-router-dom';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import { AuthContext } from '../contexts/AuthContext';

const ProductCard = ({ product }) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const openModal = () => setModalOpen(true);
	const closeModal = () => setModalOpen(false);
	const {addToCart, setOpenCart} = useContext(AuthContext);

	const add = async (product) => {
		setLoading(true);
		await addToCart(product);
		setLoading(false);
		setOpenCart(true);
	} 

	return (
		<div className="p-1">
			<div className="max-w-md w-full rounded overflow-hidden lg:p-5 p-1 bg-white relative hover:shadow-xl transition-shadow duration-300 group overflow-visible">
					<Link to={`/product/${product.slug}`} className="w-full flex justify-center">
						<img className="max-h-96" src={product.medias[0].url} alt={product.name} />
					</Link>
					<div className="lg:px-6 px-1 py-4 flex flex-col justify-between">
							<div>
									<Link to={`/product/${product.slug}`} className="lg:text-lg text-sm mb-1 hover:text-blue-500 cursor-pointer transition duration-300">{product.name}</Link>
									<div className="flex items-baseline">
											<div className="text-lg">{product.price} cfa</div>
											{product.originalPrice && (
													<div className="text-sm text-gray-500 line-through ml-2">${product.originalPrice}</div>
											)}
									</div>
									<div className="text-sm"></div>
							</div>
							<div className="text-gray-700 text-base">{product.store}</div>

					<div className="absolute bottom-0 hidden group-hover:block bg-white translate-y-20 w-full left-0 rounded shadow-xl px-7 transition-shadow duration-300 z-10">
							<div className="flex items-center flex-col p-3">
									<button className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-6 w-full rounded" disabled={isLoading} onClick={() => add(product)}>
									{isLoading ? <div className="flex justify-center items-center"><span className="spinner"></span></div> : 'Ajouter au panier'}
									</button>
									<button onClick={openModal} className="mt-2 py-2 px-6 w-full border-2 border-blue-500 bg-white text-blue-500 rounded hover:bg-blue-100">
											Aperçu
									</button>
									</div>
							</div>
					</div>
					<Modal isOpen={isModalOpen} onClose={closeModal}>
							<div className="flex items-center lg:flex-row flex-col">
									<img className="lg:w-1/2 w-full mb-4" src={product.medias[0].url} alt={product.name} />
									<div className="lg:w-1/2 w-full">
											<h2 className="text-2xl font-semibold mb-2">{product.name}</h2>
											<span className="text-xl font-semibold">{product.price}</span> cfa
											{product.originalPrice && <p className="text-sm line-through text-gray-500">${product.originalPrice}</p>}
											<div className="w-full grid justify-items-center">
													<button className="text-white mt-4 w-1/2 py-6 rounded submit flex items-center justify-center space-x-2 text-center font-semibold"
													onClick={() => add(product)}>
															{isLoading ? <div className="flex justify-center items-center"><span className="spinner"></span></div> : <><ShoppingCartIcon className="w-6 h-6" /> Ajouter au panier</>}
													</button>
											</div>
											<div className="w-full shadow px-3 py-4 mt-3 flex flex-col">
													<span className="text-gray-500">Boutique</span>
													<span className="font-semibold">{product.shop.name}</span>
											</div>
									</div>
							</div>
					</Modal>
			</div>
		</div>
	);
}

export default ProductCard;
