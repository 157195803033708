import React from 'react';
import { Helmet } from 'react-helmet-async';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <Helmet>
        <title>Conditions d'utilisation - DeffCi</title>
        <meta name="description" content="Lisez les Conditions d'utilisation de DeffCi pour comprendre les règles et responsabilités liées à l'utilisation de notre plateforme e-commerce au Sénégal." />
        <meta name="keywords" content="Conditions d'utilisation, DeffCi, règles, responsabilités, e-commerce, Sénégal" />
        
        <meta property="og:title" content="Conditions d'utilisation - DeffCi" />
        <meta property="og:description" content="Découvrez les termes et conditions qui régissent l'utilisation de la plateforme DeffCi." />
        <meta property="og:image" content="https://deffci.com/logo.jpg" />
        <meta property="og:url" content="https://deffci.com/conditions-d-utilisation" />
        <meta property="og:type" content="website" />
        
        <meta name="twitter:card" content="https://deffci.com/logo.jpg" />
        <meta name="twitter:title" content="Conditions d'utilisation - DeffCi" />
        <meta name="twitter:description" content="Lisez les Conditions d'utilisation de DeffCi pour mieux comprendre vos droits et responsabilités." />
        <meta name="twitter:image" content="https://deffci.com/logo.jpg" />
      </Helmet>
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-8">
        <h1 className="text-3xl font-bold mb-6">Conditions d'utilisation</h1>
        <p className="mb-4">
          Bienvenue sur notre site de vente en ligne. En accédant à notre site et en utilisant nos services, vous acceptez de vous conformer aux présentes conditions d'utilisation. Veuillez les lire attentivement avant d'utiliser notre site.
        </p>

        <h2 className="text-2xl font-semibold mb-4">1. Acceptation des Conditions</h2>
        <p className="mb-4">
          En accédant ou en utilisant notre site, vous acceptez les présentes conditions d'utilisation, y compris notre politique de confidentialité. Si vous n'acceptez pas ces conditions, vous ne devez pas utiliser notre site.
        </p>

        <h2 className="text-2xl font-semibold mb-4">2. Définitions</h2>
        <p className="mb-4">
          <strong>"Site" :</strong> désigne notre plateforme de vente en ligne, incluant le site web et toute application mobile associée.
        </p>
        <p className="mb-4">
          <strong>"Utilisateur" :</strong> désigne toute personne qui accède ou utilise notre site, qu'elle soit acheteur ou vendeur.
        </p>
        <p className="mb-4">
          <strong>"Vendeur" :</strong> désigne toute entreprise ou personne physique qui s'inscrit sur notre site pour vendre des produits.
        </p>
        <p className="mb-4">
          <strong>"Acheteur" :</strong> désigne toute personne qui achète des produits via notre site.
        </p>

        <h2 className="text-2xl font-semibold mb-4">3. Inscription et Comptes</h2>
        <h3 className="text-xl font-semibold mb-3">3.1 Création de Compte</h3>
        <p className="mb-4">
          Pour utiliser certains services de notre site, vous devez créer un compte en fournissant des informations exactes et complètes. Vous êtes responsable de la confidentialité de votre compte et de votre mot de passe.
        </p>

        <h3 className="text-xl font-semibold mb-3">3.2 Responsabilité du Compte</h3>
        <p className="mb-4">
          Vous êtes entièrement responsable de toutes les activités qui se déroulent sous votre compte. Vous devez nous informer immédiatement de toute utilisation non autorisée de votre compte ou de toute autre violation de sécurité.
        </p>
        
        <h2 className="text-2xl font-semibold mb-4">4. Utilisation de notre Site</h2>
        <p className="mb-4">
          Vous acceptez d'utiliser notre site uniquement à des fins légales. Vous vous engagez à ne pas utiliser notre site pour :
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li className="mb-2">Publier, distribuer ou diffuser tout contenu illégal, offensant, diffamatoire ou inapproprié.</li>
          <li className="mb-2">Usurper l'identité d'une autre personne ou entité.</li>
          <li className="mb-2">Interférer avec le fonctionnement du site ou y accéder de manière non autorisée.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">5. Vendeurs</h2>
        <p className="mb-4">
          Les entreprises souhaitant vendre des produits doivent s'inscrire en tant que vendeur. Nous nous réservons le droit de refuser ou de suspendre tout compte de vendeur qui ne respecte pas nos conditions.
        </p>

        <h2 className="text-2xl font-semibold mb-4">6. Achats</h2>
        <p className="mb-4">
          Lorsque vous passez une commande sur notre site, vous acceptez d'acheter le produit conformément à sa description. Nous nous réservons le droit de refuser ou d'annuler toute commande.
        </p>
        
        <h2 className="text-2xl font-semibold mb-4">Contact</h2>
        <p className="mb-4">
          Si vous avez des questions concernant ces conditions, veuillez nous contacter à <a href="mailto:contact@deffci.com" className="text-blue-500 underline">contact@deffci.com</a>.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
