import {Routes, Route} from "react-router-dom";
import Header from './components/Header'
import Home from './pages/Home'
import Search from './pages/Search';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import Auth from "./components/Auth";
import Footer from "./components/Footer";
import { AuthProvider } from "./contexts/AuthContext";
import SellerDashboard from "./pages/seller/SellerDashboard";
import AddProduct from "./pages/seller/AddProduct";
import Product from "./pages/Product";
import Checkout from "./pages/Checkout";
import Products from "./pages/seller/Products";
import ManageOrders from "./pages/seller/ManageOrders";
import APropos from "./pages/APropos";
import ForShops from "./pages/ForShops";
import RegisterSeller from "./pages/RegisterSeller";
import Orders from "./pages/Orders";
import Settings from "./pages/user/Settings";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/ScrollToTop";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PrivateRoute from "./middleware/PrivateRoute";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <AuthProvider>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={ <><Header showSidebarProp={true} unfixedProp={true} /><Home /> <Footer /></>} />
            <Route path="/product/:slug" element={ <><Header /><Product /><Footer /></>} />
            <Route path="/search" element={ <><Header /><Search /><Footer /></>} />
            <Route path="/category/:category" element={ <><Header /><Search /><Footer /></>} />
            <Route path="/checkout" element={ <PrivateRoute><Header /><Checkout /><Footer /></PrivateRoute>} />
            <Route path="/account/orders" element={<><Header/> <Orders/><Footer/></>} />
            <Route path="/account" element={ <><Auth /><Footer /></>} />
            <Route path="/a-propos" element={ <><Header /><APropos /><Footer /></>} />
            <Route path="/contactez-nous" element={ <><Header /><Contact /><Footer /></>} />
            <Route path="/devenez-vendeur" element={ <><Header /><ForShops /><Footer /></>} />
            <Route path="/conditions-d-utilisation" element={ <><Header /><TermsOfService /><Footer /></>} />
            <Route path="/politique-de-confidentialite" element={ <><Header /><PrivacyPolicy /><Footer /></>} />
            <Route path="/inscription-vendeurs" element={ <><RegisterSeller /><Footer /></>} />
            <Route path="/user/settings" element={ <><Header /><Settings /><Footer /></>} />
            <Route path="/espace-vendeur" element={<><Header /><Products /><Footer /></>} />
            <Route path="/espace-vendeur/products" element={<><Header /><Products /><Footer /></>} />
            <Route path="/espace-vendeur/ajouter-un-produit" element={<><Header /><AddProduct /><Footer /></>} />
            <Route path="/espace-vendeur/commandes" element={<><Header /><ManageOrders /><Footer /></>} />

            <Route element={NotFoundPage} />
          </Routes>
        </AuthProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
