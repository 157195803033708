import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

const Settings = () => {
  const {authUser} = useContext(AuthContext);
  const [profile, setProfile] = useState({ firstName: '', lastName: '', email: '' });
  const [shippingAddress, setShippingAddress] = useState({ address: '', city: '', zip: '' });
  const [paymentMethod, setPaymentMethod] = useState({ cardNumber: '', expiry: '' });

  useEffect(() => {
    console.log(authUser);
    setProfile({firstName: authUser.firstName, lastName: authUser.lastName, email: authUser.email})
  }, [authUser]);

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setShippingAddress({ ...shippingAddress, [name]: value });
  };

  const handleSave = () => {
    // Logique pour enregistrer les paramètres
    console.log('Saved:', { profile, shippingAddress, paymentMethod });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-2xl">
        <h2 className="text-2xl font-semibold mb-6">E-commerce Settings</h2>
        
        <section className="mb-6">
          <h3 className="text-xl font-semibold mb-4">Profile Information</h3>
          <div className="mb-4">
            <label className="block text-gray-700">Prénom</label>
            <input
              type="text"
              name="firstName"
              value={profile.firstName}
              onChange={handleProfileChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Nom</label>
            <input
              type="text"
              name="lastName"
              value={profile.lastName}
              onChange={handleProfileChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={profile.email}
              onChange={handleProfileChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </section>
        
        <section className="mb-6">
          <h3 className="text-xl font-semibold mb-4">Adresse de livraison</h3>
          <div className="mb-4">
            <label className="block text-gray-700">Adresse</label>
            <input
              type="text"
              name="address"
              value={shippingAddress.address}
              onChange={handleAddressChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Ville</label>
            <input
              type="text"
              name="city"
              value={shippingAddress.city}
              onChange={handleAddressChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">ZIP Code</label>
            <input
              type="text"
              name="zip"
              value={shippingAddress.zip}
              onChange={handleAddressChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </section>

        <button
          onClick={handleSave}
          className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Settings;
