import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white mt-24 mb-14 lg:mb-0">
      <div className="container mx-auto max-w-7xl px-6 py-8">
        <div className="grid lg:grid-cols-3 justify-between">
          <div className="my-3">
            <h2 className="font-bold text-lg mb-4">DeffCi.com</h2>
            <ul className="list-none">
              <li><Link to="/a-propos" className="text-gray-400 hover:text-white">A propos de nous</Link></li>
              <li><Link to="/devenez-vendeur" className="text-gray-400 hover:text-white">Vendre sur DeffCi.Com</Link></li>
              <li><Link to="/contactez-nous" className="text-gray-400 hover:text-white">Contactez nous</Link></li>
              <li><Link to="/politique-de-confidentialite" className="text-gray-400 hover:text-white">Politique de confidentialité</Link></li>
              <li><Link to="/conditions-d-utilisation" className="text-gray-400 hover:text-white">Conditions d'utilisation</Link></li>
            </ul>
          </div>
          <div className="my-3">
            <h2 className="font-bold text-lg mb-4">Contact</h2>
            <p className="text-gray-400">Email: <a href="mailto:contact@deffci.com">contact@deffci.com</a></p>
            <p className="text-gray-400">Tel: 76.658.65.27</p>
          </div>
          <div className="my-3">
            <h2 className="font-bold text-lg mb-4">Réseaux sociaux</h2>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-white">Facebook</a>
              <a href="#" className="text-gray-400 hover:text-white">Twitter</a>
              <a href="#" className="text-gray-400 hover:text-white">Instagram</a>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center text-gray-400">
          <p>© 2024 Deffci. Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
