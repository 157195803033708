import React from "react";
import { Helmet } from "react-helmet-async";

function APropos(){

  return (
    <div class="mx-auto max-w-7xl">
      <Helmet>
        <title>À propos de DeffCi - Votre plateforme e-commerce au Sénégal</title>
        <meta name="description" content="Découvrez DeffCi, une plateforme e-commerce dédiée à offrir la meilleure expérience d'achat en ligne au Sénégal. Notre mission : connecter les clients avec les meilleurs produits." />
        <meta name="keywords" content="À propos, DeffCi, e-commerce, Sénégal, mission, vision, valeurs" />
        
        <meta property="og:title" content="À propos de DeffCi - Votre plateforme e-commerce au Sénégal" />
        <meta property="og:description" content="Apprenez-en plus sur DeffCi, notre mission, et comment nous œuvrons pour offrir une expérience d'achat en ligne exceptionnelle au Sénégal." />
        <meta property="og:image" content="https://deffci.com/logo.jpg" />
        <meta property="og:url" content="https://deffci.com/a-propos" />
        <meta property="og:type" content="website" />
        
        <meta name="twitter:card" content="https://deffci.com/logo.jpg" />
        <meta name="twitter:title" content="À propos de DeffCi - Votre plateforme e-commerce au Sénégal" />
        <meta name="twitter:description" content="Découvrez notre mission et comment DeffCi transforme l'e-commerce au Sénégal." />
        <meta name="twitter:image" content="https://deffci.com/logo.jpg" />
      </Helmet>
      <div className="flex flex-col items-center">
        <div className="text-4xl text-center w-full p-3 lg:w-1/2 mx-auto min-h-96 flex items-center">
          Nous vous donnons le pouvoir de développer votre commerce en ligne et de toucher une clientèle plus large
        </div>
        <div className="w-full lg:w-4/5 p-3 flex lg:flex-row flex-col">
          <div className="flex flex-col items-center text-center m-6">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 32 32"><title>choose-a-theme</title><path d="M28 0.667h-24c-1.473 0-2.667 1.193-2.667 2.667v25.333c0 1.473 1.193 2.667 2.667 2.667h24c1.473 0 2.667-1.193 2.667-2.667v-25.333c0-1.473-1.193-2.667-2.667-2.667zM29.333 28.667c0 0.733-0.6 1.333-1.333 1.333h-24c-0.733 0-1.333-0.6-1.333-1.333v-24h26.667v24z"></path><path d="M7.333 16.667h6.667c0.733 0 1.333-0.6 1.333-1.333v-6.667c0-0.733-0.6-1.333-1.333-1.333h-6.667c-0.733 0-1.333 0.6-1.333 1.333v6.667c0 0.733 0.6 1.333 1.333 1.333zM6.933 8.667c0-0.22 0.18-0.4 0.4-0.4h6.667c0.22 0 0.4 0.18 0.4 0.4v6.667c0 0.22-0.18 0.4-0.4 0.4h-6.667c-0.22 0-0.4-0.18-0.4-0.4v-6.667z"></path><path d="M7.167 27.387h6.667c0.733 0 1.333-0.6 1.333-1.333v-6.667c0-0.733-0.6-1.333-1.333-1.333h-6.667c-0.733 0-1.333 0.6-1.333 1.333v6.667c0 0.74 0.6 1.333 1.333 1.333zM6.773 19.387c0-0.22 0.18-0.4 0.4-0.4h6.667c0.22 0 0.4 0.18 0.4 0.4v6.667c0 0.22-0.18 0.4-0.4 0.4h-6.667c-0.22 0-0.4-0.18-0.4-0.4v-6.667z"></path><path d="M18 27.333h6.667c0.733 0 1.333-0.6 1.333-1.333v-6.667c0-0.733-0.6-1.333-1.333-1.333h-6.667c-0.733 0-1.333 0.6-1.333 1.333v6.667c0 0.733 0.6 1.333 1.333 1.333zM17.6 19.333c0-0.22 0.18-0.4 0.4-0.4h6.667c0.22 0 0.4 0.18 0.4 0.4v6.667c0 0.22-0.18 0.4-0.4 0.4h-6.667c-0.22 0-0.4-0.18-0.4-0.4v-6.667z"></path><path d="M18 16.667h6.667c0.733 0 1.333-0.6 1.333-1.333v-6.667c0-0.733-0.6-1.333-1.333-1.333h-6.667c-0.733 0-1.333 0.6-1.333 1.333v6.667c0 0.733 0.6 1.333 1.333 1.333zM19.567 11.267l1.193 1.193 2.333-2.333 0.707 0.707-3.040 3.040-1.9-1.9 0.707-0.707z"></path></svg>
            </div>
            <div className="text-2xl mt-6">Ajoutez vos produits</div>
            <div className="mt-3 text-lg">Ajoutez les produits de votre choix avec les photos et des descriptions complets</div>
          </div>
          <div className="flex flex-col items-center text-center m-6">
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 32 32"><title>add-products</title><path d="M28.707 16.4c-0.213-0.12-0.48-0.12-0.693 0.007l-2.14 1.26c-0.373 0.22-0.6 0.62-0.6 1.053v2.52c0 0.247 0.133 0.473 0.347 0.6 0.107 0.060 0.227 0.093 0.34 0.093 0.12 0 0.24-0.033 0.353-0.093l2.14-1.26c0.373-0.22 0.6-0.62 0.6-1.053v-2.527c0-0.247-0.133-0.473-0.347-0.6z"></path><path d="M29.527 6.44l-8.667-5.1c-1.040-0.613-2.34-0.613-3.38 0l-8.667 5.1c-1.013 0.593-1.64 1.7-1.64 2.873v5.873c-3.567 0.627-6.28 3.74-6.28 7.48 0 4.193 3.413 7.607 7.607 7.607 2.907 0 5.433-1.64 6.713-4.040l2.26 1.333c0.52 0.307 1.107 0.46 1.693 0.46s1.167-0.153 1.693-0.46l8.667-5.1c1.013-0.593 1.64-1.7 1.64-2.873v-10.28c0-1.18-0.627-2.28-1.64-2.873zM18.153 2.487c0.627-0.367 1.4-0.367 2.027 0l8.667 5.1c0.107 0.060 0.2 0.147 0.293 0.227l-2.333 1.373-10.020-5.893 1.367-0.807zM15.473 4.067l10.693 6.293 0.013 3.78c0 0.233-0.127 0.46-0.327 0.58l-2.34 1.373-0.013-4.207-10.66-6.267 2.633-1.553zM11.333 23.167h-2.167v2.167h-1v-2.167h-2.167v-1h2.167v-2.167h1v2.167h2.167v1zM18.5 26.56c-0.12-0.040-0.24-0.080-0.347-0.147l-2.413-1.42c0.24-0.733 0.367-1.52 0.367-2.333 0-4.193-3.413-7.607-7.607-7.607v-5.747c0-0.113 0.027-0.22 0.047-0.333l9.953 5.853v11.733zM9.193 7.813c0.093-0.080 0.18-0.167 0.293-0.227l2.040-1.2 10.020 5.893-2.38 1.4-9.973-5.867zM29.833 19.593c0 0.707-0.38 1.367-0.987 1.72l-8.667 5.1c-0.113 0.067-0.227 0.107-0.347 0.147v-11.727l2.34-1.373 0.007 2.633c0 0.487 0.253 0.913 0.673 1.153 0.207 0.12 0.433 0.18 0.66 0.18 0.233 0 0.467-0.060 0.673-0.187l2.34-1.373c0.613-0.36 0.987-1.020 0.987-1.733l-0.013-3.807 2.287-1.347c0.020 0.113 0.047 0.22 0.047 0.333v10.28z"></path></svg>
            </div>
            <div className="text-2xl mt-6">Créez votre boutique</div>
            <div className="mt-3 text-lg">Choisissez le type de boutique que vous voulez vendre parmis les catégories disponibles</div>
          </div>
          <div className="flex flex-col items-center text-center m-6">
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 32 32"><title>start-selling</title><path d="M28.4 1.867c-0.253-0.333-0.647-0.533-1.067-0.533h-22.667c-0.42 0-0.813 0.2-1.067 0.533l-3.6 4.8v3.327c0 1.593 1.12 3.013 2.667 3.28v14.727c0 1.473 1.193 2.667 2.667 2.667h21.333c1.473 0 2.667-1.193 2.667-2.667v-14.727c1.547-0.267 2.667-1.687 2.667-3.28v-3.327l-3.6-4.8zM26.667 29.393h-21.333c-0.767 0-1.393-0.627-1.393-1.393v-14.753c1.413-0.333 2.46-1.6 2.46-3.113 0 1.767 1.433 3.2 3.2 3.2s3.2-1.433 3.2-3.2c0 1.767 1.433 3.2 3.2 3.2s3.2-1.433 3.2-3.2c0 1.767 1.433 3.2 3.2 3.2s3.2-1.433 3.2-3.2c0 1.513 1.053 2.78 2.46 3.113v14.753c0 0.767-0.627 1.393-1.393 1.393z"></path><path d="M20.773 17.167h2.807l-7.007 6.96-4.927-3.373-4.78 4.84 0.713 0.707 4.187-4.247 4.92 3.367 7.587-7.533v2.78h1v-4.5h-4.5z"></path></svg>
            </div>
            <div className="text-2xl mt-6">Commencez à vendre</div>
            <div className="mt-3 text-lg">Configurez les paiements et l'expédition, et commencez vente.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default APropos;
