import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import DashboardContent from './Dashboard';
import Sidebar from './Sidebar';
import { get as fetchData } from '../../services/apiService';

const Products = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try{
      const response = await fetchData('/products');
      setProducts(response.products);
    }catch(e){
      console.log('Une erreur est survenue');
    }
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <div class="container mx-auto max-w-7xl py-5 mt-6 flex">
        <Sidebar />
        <div class="flex flex-col w-full mr-3 bg-white p-3">
          <div class="flex justify-end mb-4">
            <Link to="/espace-vendeur/ajouter-un-produit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Ajouter un produit
            </Link>
          </div>
          <div class="overflow-x-auto">
            <div class="min-w-full py-2 align-middle inline-block">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"></div>
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Nom du produit
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Categorie
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Prix
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Stock
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  {products && products.map((product, index) => (
                  <tr className="hover:bg-blue-50">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm font-medium text-gray-900">
                        <Link to={`/product/${product.slug}`} target="_blank">
                          {product.name}
                        </Link>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-500">{product.category.name}</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-500">{product.price}</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-500">{product.quantity}</div>
                    </td>
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
}

export default Products;
