import React, { useState } from 'react';
import { post } from '../services/apiService';
import { Helmet } from 'react-helmet-async';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    telephone: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Le nom est requis';
    if (!formData.telephone) newErrors.telephone = 'Le numéro de téléphone est requis';
    if (!formData.email) {
      newErrors.email = "l'adresse email est requise";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "l'adresse email n'est pas valide";
    }
    if (!formData.message) newErrors.message = 'Le message est requis';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Logic to handle form submission goes here
      console.log('Form data submitted:', formData);
      try{
        await post("/contact", formData);
        setSuccessMessage('merci pour votre message. Nous vous recontacterons incessamment sous peu.');
        setFormData({ name: '', email: '', message: '' });
      }catch(e){
        console.log("Une erreur est survenue!");
      }
    }
  };

  return (
    <div class="mx-auto max-w-7xl">
      <Helmet>
        <title>Contactez-nous - DeffCi</title>
        <meta name="description" content="Besoin d'aide ou d'informations ? Contactez notre équipe DeffCi pour toute assistance concernant vos achats en ligne au Sénégal." />
        <meta name="keywords" content="contact, assistance, support, DeffCi, e-commerce, Sénégal" />
        
        <meta property="og:title" content="Contactez-nous - DeffCi" />
        <meta property="og:description" content="Contactez l'équipe DeffCi pour toute question ou assistance liée à vos achats en ligne." />
        <meta property="og:image" content="https://deffci.com/logo.jpg" />
        <meta property="og:url" content="https://deffci.com/contactez-nous" />
        <meta property="og:type" content="website" />
        
        <meta name="twitter:card" content="https://deffci.com/logo.jpg" />
        <meta name="twitter:title" content="Contactez-nous - DeffCi" />
        <meta name="twitter:description" content="Besoin d'aide ? Contactez DeffCi pour toute assistance concernant vos achats en ligne." />
        <meta name="twitter:image" content="https://deffci.com/logo.jpg" />
      </Helmet>
      <div className="mt-12 w-full lg:w-1/2 mx-auto p-3">
        <h2 className="text-2xl font-bold mb-4 text-center">Formulaire de contact</h2>
        {successMessage && (
          <div className="mb-4 text-green-600 text-center">
            {successMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Nom</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                errors.name ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {errors.name && (
              <p className="mt-2 text-sm text-red-600">{errors.name}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                errors.email ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {errors.email && (
              <p className="mt-2 text-sm text-red-600">{errors.email}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="telephone" className="block text-sm font-medium text-gray-700">Téléphone</label>
            <input
              type="text"
              id="telephone"
              name="telephone"
              value={formData.telephone}
              onChange={handleChange}
              className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                errors.telephone ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {errors.telephone && (
              <p className="mt-2 text-sm text-red-600">{errors.telephone}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                errors.message ? 'border-red-500' : 'border-gray-300'
              }`}
            ></textarea>
            {errors.message && (
              <p className="mt-2 text-sm text-red-600">{errors.message}</p>
            )}
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Envoyer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
