import React, { useState, useRef, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';

function AccountDropdown() {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const dropdownRef = useRef(null);

    const { authUser, logout } = useContext(AuthContext);

    // Gérer les clics en dehors du dropdown pour le fermer
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const log_out = async () => {
        setLoading(true);
        await logout();
        setLoading(false);
        window.location = "/account"
    }

    return (
        <div className="relative pr-2" ref={dropdownRef}>
            <button onClick={() => setIsOpen(!isOpen)} className="flex items-center space-x-2 p-2 rounded-full focus:outline-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                </svg>
                <span className="hidden lg:flex">{authUser.firstName}</span>
            </button>
            {isOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Your Profile</a>
                    <Link to="/user/settings" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Paramètres</Link>
                    <a href="#" onClick={log_out} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-between">
                        <span>Déconnexion</span>
                        {isLoading && (<span className="spinner"></span>)}
                    </a>
                </div>
            )}
        </div>
    );
}

export default AccountDropdown;
