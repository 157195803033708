import React, { useContext } from "react";
import { XMarkIcon, UserIcon, UserPlusIcon, HeartIcon, QuestionMarkCircleIcon, ShoppingCartIcon, CogIcon, ArrowLeftEndOnRectangleIcon, BriefcaseIcon, CubeIcon } from '@heroicons/react/24/outline';
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

function AccountSidebar({isOpen, closeSidebar}) {
  const { authUser, logout } = useContext(AuthContext);

  const log_out = async () => {
    await logout();
    window.location = "/account"
  }
  return (
    <>
      <div className="fixed top-0 right-0 w-2/3 lg:w-1/5 h-full bg-white shadow-lg py-5 z-50 overflow-y-auto transition-transform duration-500 transform"
      style={{ transform: isOpen ? 'translateX(0)' : 'translateX(100%)' }}>
        <h2 className="text-2xl font-semibold mb-6 mx-3">Compte</h2>
        <button onClick={closeSidebar} className="absolute top-6 right-3 text-xl text-gray-800 hover:text-gray-600 focus:outline-none">
          <XMarkIcon className="h-6 w-6 text-gray-800" />
        </button>
        <nav className="flex flex-col">
          {authUser ? (
          <>
            <Link to="/profile" className="hover:bg-slate-100 px-7 py-4 rounded flex items-center" onClick={closeSidebar}>
              <UserIcon className="h-5 w-5 mr-5" />
              <span>Profil</span>
            </Link>
            <Link to="/user/settings" className="hover:bg-slate-100 px-7 py-4 rounded flex items-center" onClick={closeSidebar}>
              <CogIcon className="h-5 w-5 mr-5" />
              <span>Paramètres</span>
            </Link>
            {authUser && authUser.role === 1 && (
              <>
                <Link to="/account/orders" className="hover:bg-slate-100 px-7 py-4 rounded flex items-center" onClick={closeSidebar}>
                  <ShoppingCartIcon className="h-5 w-5 mr-5" />
                  <span>Commandes</span>
                </Link>
              </>
            )}

            {authUser && authUser.role === 2 && (
              <>
                <Link to='/espace-vendeur' className="hover:bg-slate-100 px-7 py-4 rounded flex items-center" onClick={closeSidebar}>
                  <BriefcaseIcon className="h-5 w-5 mr-5" />
                  <span>Espace vendeur</span>
                </Link>
                <Link to='/espace-vendeur/products' className="hover:bg-slate-100 px-7 py-4 rounded flex items-center" onClick={closeSidebar}>
                  <CubeIcon className="h-5 w-5 mr-5" />
                  <span>Produits</span>
                </Link>
                <Link to='/espace-vendeur/commandes' className="hover:bg-slate-100 px-7 py-4 rounded flex items-center" onClick={closeSidebar}>
                  <ShoppingCartIcon className="h-5 w-5 mr-5" />
                  <span>Commandes</span>
                </Link>
                {/* <Link to='#' className='p-3'>
                  Finances
                </Link>
                <Link to='#' className='p-3'>
                  Service Client
                </Link>
                <Link to='#' className='p-3'>
                  Paramètres
                </Link> */}
              </>
            )}
            <Link to="/logout" className="hover:bg-slate-100 px-7 py-4 rounded flex items-center text-red-500" onClick={log_out}>
                  <ArrowLeftEndOnRectangleIcon className="h-5 w-5 mr-5" />
                  <span>Déconnexion</span>
            </Link>
          </>
          ) : (
          <>
            <Link to="/account/" className="hover:bg-slate-100 px-7 py-4 rounded flex items-center">
              <UserIcon className="h-5 w-5 mr-5" />
              <span>Connexion</span>
            </Link>
            <Link to="/account/#register" className="hover:bg-slate-100 px-7 py-4 rounded flex items-center">
              <UserPlusIcon className="h-5 w-5 mr-5" />
              <span>Créer son compte</span>
            </Link>
            <Link to="#" className="hover:bg-slate-100 px-7 py-4 rounded flex items-center">
              <HeartIcon className="h-5 w-5 mr-5" />
              <span>Favoris</span>
            </Link>
          </>
          )}
        </nav>
      </div>
      {isOpen && (<div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-30 transform duration-500 ease-in-out" onClick={closeSidebar} />)}
    </>
  )
}

export default AccountSidebar;