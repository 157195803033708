import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const apiService = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

apiService.interceptors.request.use(function(config) {
  const token = localStorage.getItem('accessToken');
  if(token)
    config.headers.Authorization =  `Bearer ${token}`;
  return config;
});

apiService.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('accessToken');
    window.location = '/account';
  }
  return Promise.reject(error);
});


// Fonction pour obtenir des données (Read)
export const get = async (endpoint) => {
  try {
    const response = await apiService.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postWithFile = async (endpoint, formData) => {

  try {
    const response = await apiService.post(endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fonction pour créer une entrée (Create)
export const post = async (endpoint, data) => {
  try {
    const response = await apiService.post(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fonction pour mettre à jour une entrée (Update)
export const put = async (endpoint, data) => {
  try {
    const response = await apiService.put(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fonction pour supprimer une entrée (Delete)
export const deleteData = async (endpoint) => {
  try {
    const response = await apiService.delete(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('authToken');
  window.location = '/login';
};


export default apiService;
