import React, { useContext, useEffect, useRef, useState } from "react";
import { XMarkIcon, UserIcon, UserPlusIcon, HeartIcon, QuestionMarkCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Link } from "react-router-dom";
import * as Heroicons from '@heroicons/react/24/outline';
import { AuthContext } from '../contexts/AuthContext';

function CategorySidebar({showSidebarProp, unfixedProp, sendToParent}) {
  const [showSidebar, setShowSidebar] = useState(showSidebarProp);
  const { authUser, categories } = useContext(AuthContext);
  const [activeCategory, setActiveCategory] = useState(null);
  const [lastCategory, setLastCategory] = useState(null);
  const [subCategoryHeight, setSubCategoryHeight] = useState(0);
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setShowSidebar(showSidebarProp)
  }, [showSidebarProp]);

  useEffect(() => {
    if (div1Ref.current) {
      const div1Height = div1Ref.current.offsetHeight;
      // div2Ref.current.style.height = `${div1Height}px`;
      setHeight(div1Height);
    }
  }, []);
  useEffect(() => {
    if (div2Ref.current) {
      div2Ref.current.style.height = `${height}px`;
    }
  }, [height]);
  
  const toggleActiveCategory = (categorie) => {
    setActiveCategory(categorie);
    if(categorie)
      setLastCategory(categorie);
  }

  useEffect(() => {
    if (div1Ref.current && activeCategory) {
      const height = div1Ref.current.clientHeight;
      setSubCategoryHeight(height);
    }
  }, [activeCategory]);
  
  const icons = [
    {id: 1, iconName: 'DevicePhoneMobileIcon'},
    {id: 7, iconName: 'ShoppingBagIcon'},
    {id: 13, iconName: 'HomeIcon'},
    {id: 19, iconName: 'HeartIcon'},
    {id: 25, iconName: 'AcademicCapIcon'},
    {id: 31, iconName: 'PuzzlePieceIcon'},
    {id: 37, iconName: 'ShoppingCartIcon'},
    {id: 43, iconName: 'UserGroupIcon'},
    {id: 49, iconName: 'BookOpenIcon'},
    {id: 55, iconName: 'TruckIcon'},
  ];

  return (
    <>
      <nav className="mx-auto flex max-w-7xl w-full relative ">
        <div className='flex'>
          <a className='flex space-between cursor-pointer pl-6 pr-3 py-2 items-center w-72 shadow' onClick={() => {setShowSidebar(!showSidebar)}}
          onBlur={() => {if(!unfixedProp)setShowSidebar(false)}}>
            <span className='mr-4'>
            <svg width="24" height="24" aria-hidden="true" role="img" focusable="false" viewBox="0 0 32 32"><path d="M7.2 4.8c-1.328 0-2.4 1.072-2.4 2.4s1.072 2.4 2.4 2.4 2.4-1.072 2.4-2.4-1.072-2.4-2.4-2.4v0z"></path><path d="M26.4 4.8c-1.328 0-2.4 1.072-2.4 2.4s1.072 2.4 2.4 2.4 2.4-1.072 2.4-2.4-1.072-2.4-2.4-2.4v0z"></path><path d="M16.8 4.8c-1.328 0-2.4 1.072-2.4 2.4s1.072 2.4 2.4 2.4 2.4-1.072 2.4-2.4-1.072-2.4-2.4-2.4v0z"></path><path d="M7.2 24c-1.328 0-2.4 1.072-2.4 2.4s1.072 2.4 2.4 2.4 2.4-1.072 2.4-2.4-1.072-2.4-2.4-2.4v0z"></path><path d="M26.4 24c-1.328 0-2.4 1.072-2.4 2.4s1.072 2.4 2.4 2.4 2.4-1.072 2.4-2.4-1.072-2.4-2.4-2.4v0z"></path><path d="M16.8 24c-1.328 0-2.4 1.072-2.4 2.4s1.072 2.4 2.4 2.4 2.4-1.072 2.4-2.4-1.072-2.4-2.4-2.4v0z"></path><path d="M7.2 14.4c-1.328 0-2.4 1.072-2.4 2.4s1.072 2.4 2.4 2.4 2.4-1.072 2.4-2.4-1.072-2.4-2.4-2.4v0z"></path><path d="M26.4 14.4c-1.328 0-2.4 1.072-2.4 2.4s1.072 2.4 2.4 2.4 2.4-1.072 2.4-2.4-1.072-2.4-2.4-2.4v0z"></path><path d="M16.8 14.4c-1.328 0-2.4 1.072-2.4 2.4s1.072 2.4 2.4 2.4 2.4-1.072 2.4-2.4-1.072-2.4-2.4-2.4v0z"></path></svg>
            </span>
            <span className='flex-auto'>
              Catégories
            </span>
            <span className="justify-end">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>
            </span>
          </a>
          {showSidebar && (
        <div className="w-72 h-full absolute bg-white mt-16 z-30">
          <div className="lg:shadow lg:shadow-xl bg-white" ref={div1Ref}>
            {categories && categories.map((category) => {
              const Icon = Heroicons[icons.filter(i => i.id === category.id)[0]?.iconName];
              return (
                <div className="flex justify-between lg:pr-0 pr-3" key={category.id}>
                  <Link to={`/category/${category.slug}`}
                    onMouseEnter={() => toggleActiveCategory(category.sub_categories)}
                    onMouseLeave={() => toggleActiveCategory(null)}
                    className="p-2 cursor-pointer flex-1 flex space-between px-3 py-4 border-l-2 border-transparent hover:border-black items-center"
                    onClick={sendToParent}
                  >
                    <span className="px-3 text-sm font-extrabold">
                      {Icon && <Icon className="h-5 w-5" />}
                    </span>
                    <span>{category.name}</span>
                  </Link>
                  <Heroicons.ChevronRightIcon className="w-4 lg:hidden" onClick={() => toggleActiveCategory(category.sub_categories ? category.sub_categories : null)} />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {activeCategory && (
        <div className={`container mx-auto absolute z-30 bg-white shadow-lg lg:left-72 left-0 max-w-7xl h-screen transition-all duration-500 ease-in-out`}
          onMouseEnter={() => setActiveCategory(activeCategory)}
          onMouseLeave={() => setActiveCategory(null)}
          ref={div2Ref}
          style={{ height: subCategoryHeight }}
        >
          <div>
            <button className="absolute right-0 top-0 p-3" onClick={() => setActiveCategory(null)}>
              <XMarkIcon className="w-5 h-5" />
            </button>
          </div>
          <div className="flex-auto block bg-white p-4 min:w-72 min:h-72" >
            {activeCategory.map((subCategory, index) => (
              <div key={index} className="p-2">
                <Link to={`/category/${subCategory.slug}`} onClick={sendToParent}>
                  {subCategory.name}
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
        </div>
        <div className='hidden lg:flex flex-auto mx-6 my-3 items-center text-gray-700 font-bold'>
          <Link to='/' className='p-3'>
            Accueuil
          </Link>
          {authUser && authUser.role == 1 && (
            <>
              <Link to="/account/orders" className="p-3">Commandes</Link>
            </>
          )}
          {authUser && authUser.role === 2 && (
            <>
            <Link to='/espace-vendeur' className='p-3'>
              Tableau de bord
            </Link>
            <Link to='#' className='p-3'>
              Produits
            </Link>
            <Link to='#' className='p-3'>
              Commandes
            </Link>
            <Link to='#' className='p-3'>
              Finances
            </Link>
            <Link to='#' className='p-3'>
              Service Client
            </Link>
            <Link to='#' className='p-3'>
              Paramètres
            </Link>
            </>
          )}
        </div>
      </nav>
    </>
  )
}

export default CategorySidebar;