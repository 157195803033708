import { PhotoIcon, XMarkIcon } from '@heroicons/react/24/solid';
import React, { useRef, useState } from 'react';

const DragAndDrop = ({ onImagesChange }) => {
  const [dragOver, setDragOver] = useState(false);
  const [images, setImages] = useState([]);
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    handleFiles(files);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFiles = (files) => {
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    // const imageUrls = imageFiles.map(file => URL.createObjectURL(file));
    setImages(prevImages => {
      const newImages = [...prevImages, ...imageFiles].slice(0, 10);
      onImagesChange(newImages);
      return newImages;
    });
  };

  const handleRemoveImage = (index) => {
    setImages(prevImages => {
      const newImages = prevImages.filter((_, i) => i !== index);
      onImagesChange(newImages); // Pass updated images to parent
      return newImages;
    });
  };

  return (
    <div>
      <div className="mb-4 flex flex-wrap justify-center">
        {images.map((file, index) => (
          <div key={index} className="relative w-32 h-32 m-2">
            <img src={URL.createObjectURL(file)} alt={`Selected ${index}`} className="w-full h-full object-cover border border-gray-300" />
            <button 
              className="absolute top-0 right-0 bg-white rounded-full p-1 hover:bg-gray-200" 
              onClick={() => handleRemoveImage(index)}
            >
              <XMarkIcon className="h-4 w-4 font-bold text-black" />
            </button>
          </div>
        ))}
      </div>
      <div
        className={`w-full h-56 rounded border-2 border-dashed flex cursor-pointer items-center justify-center transition-colors duration-300 ${
          dragOver ? 'bg-blue-200 border-blue-500 text-blue-500' : 'bg-gray-100 border-gray-500 text-gray-500'
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileChange}
          accept="image/*"
          multiple
        />
        <div className="flex flex-col max-h-40">
          {/* <span className="text-lg"> */}
            <PhotoIcon />
          {/* </span> */}
          <p className="text-lg">Déposez ou Cliquez ici pour ajouter les images</p>
        </div>
      </div>
    </div>
  );
};

export default DragAndDrop;
