import React, { useEffect, useState } from 'react';
import Login from './Login';
import Register from './Register';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function Auth() {
    const [activeTab, setActiveTab] = useState(window.location.hash || '#login');

    const handleTabSwitch = (tab) => {
        setActiveTab(tab);
    };
    useEffect(() => {
      const handleHashChange = () => {
        setActiveTab(window.location.hash);
      };
      window.addEventListener('hashchange', handleHashChange, false);
      return () => {
        window.removeEventListener('hashchange', handleHashChange, false);
      };
    }, []);

    return (
      <div className="mx-auto max-w-xl xl:w-1/3 lg:w-2/3 md:w-1/2 w-full h-full shadow-xl flex pt-12 mb-12">
        <Helmet>
          <title>Connexion - DeffCi</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="w-full shadow">
          <div className="flex items-center w-full justify-center">
            <Link to="/"><img className="lg:w-44 w-36" src="/logo.jpeg" alt="" /></Link>
          </div>
          <div className="flex text-lg p-6">
            <a
              className={`px-4 py-2 w-1/2 text-center cursor-pointer border-b-2 ${activeTab === '#login' ? 'text-black-700 border-gray-700' : 'border-gray-300 text-gray-500'}`}
              onClick={() => handleTabSwitch('#login')}
            >
                Connexion
            </a>
            <a
              className={`px-4 py-2 w-1/2 text-center cursor-pointer border-b-2 ${activeTab === '#register' ? 'text-black-700 border-gray-600' : 'border-gray-300 text-gray-500'}`}
              onClick={() => handleTabSwitch('#register')}
            >
              Créer son compte
            </a>
          </div>
          {activeTab === '#login' ? (
            <Login />
          ) : (
            <Register />
          )}
        </div>
      </div>
    );
}

export default Auth;
