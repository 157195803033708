import React, { useContext, useEffect, useState } from "react";
import { XMarkIcon, UserIcon, UserPlusIcon, HeartIcon, QuestionMarkCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

function CartSidebar({isOpen, closeSidebar}) {
  const { cartItems, removeCartItem, addToCart, authUser } = useContext(AuthContext);

  const handleRemove = (item) => {
    removeCartItem(item)
  }

  // if (!isOpen && !openCart) return null;
  return (
    <>
      <div className={`fixed top-0 right-0 w-2/3 lg:w-1/4 h-full bg-white shadow-lg h-full p-5 z-50 transition-all duration-500 transform ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}>
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-row">
            <h1 className="text-xl font-semibold text-gray-900 mb-6">Panier ({cartItems.length})</h1>
            <button onClick={closeSidebar} className="absolute top-6 right-3 text-xl text-gray-800 hover:text-gray-600 focus:outline-none">
              <XMarkIcon className="h-6 w-6 text-gray-800" />
            </button>
          </div>
          <div className="flex-auto">
            <ul className="space-y-6 flex-auto">
            {cartItems ? cartItems.map((cart, index) =>
              <li>
                <div className="flex items-center">
                  <div className="rounded-full overflow-hidden">
                    <img src={cart.product.medias[0].url} className="h-24 w-24 rounded-full" />
                  </div>
                  <div className="">
                    <div className="text-sm">{cart.product.name}</div>
                    <div className="text-sm">
                      <span className="text-gray-400">Boutique: </span>
                      <span className="">{cart.product.shop.name}</span>
                    </div>
                    <div><span className="font-semibold">{cart.product.price}</span> cfa</div>
                    <div className="flex justify-between w-full">
                      <div className="flex items-center mt-3">
                        <div className="w-8 h-8 border-2 flex text-center justify-center items-center text-3xl mx-3 cursor-pointer hover:border-gray-900 transition duration-200"
                        onClick={() => addToCart(cart.product, -1)}>-</div>
                        <div>{cart.quantity}</div>
                        <div className="w-8 h-8 border-2 flex text-center justify-center items-center text-3xl mx-3 cursor-pointer hover:border-gray-900 transition duration-200"
                        onClick={() => addToCart(cart.product)}>+</div>
                      </div>
                      <div className="w-6 h-6 items-center justify-right mt-3 cursor-pointer" onClick={() => handleRemove(cart)}>
                        <TrashIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ) : (
              <div>Hello</div>
            )}
            </ul>
          </div>
          <div className="mx-3">
            <div className="flex justify-between w-full">
              <span>Total</span>
              <span>{cartItems.reduce((acc, cart) => acc + cart.product.price * cart.quantity, 0)}</span>
            </div>
            {authUser ? (
              <Link to="/checkout" className="mt-3 text-white w-full font-semibold block text-center border-0 py-4 px-6 focus:outline-none rounded submit" onClick={closeSidebar}>Paiement</Link>
            ) : (
              <Link to="/account" className="mt-3 text-white w-full font-semibold block text-center border-0 py-4 px-6 focus:outline-none rounded submit" onClick={closeSidebar}>Paiement</Link>
            )}
          </div>
        </div>
      </div>
      {isOpen && (<div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-30 transform duration-500 ease-in-out" onClick={closeSidebar} />)}
    </>
  )
}

export default CartSidebar;