import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import {ViewfinderCircleIcon} from '@heroicons/react/24/solid';
import 'react-quill/dist/quill.snow.css';

import { postWithFile } from '../../services/apiService';
import DragAndDrop from '../../components/DragAndDrop';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import ReactQuill from 'react-quill';

const AddProduct = () => {
  const [productName, setProductName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [deliver, setDeliver] = useState('');
  const [stock, setStock] = useState('');
  const [deliveringPrice, setDeliveringPrice] = useState('');
  const [category, setCategory] = useState('');
  const [images, setImages] = useState([]);
  const {categories} = useContext(AuthContext);
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if(!productName || !price || !description || !category || !deliver || !stock || !deliveringPrice || images.length === 0){
      setError('Veuillez remplir tous les champs');
      setLoading(false);
      return;
    }
    // console.log({ productName, price, description, category, images });
    try{
      const formData = new FormData();
      for (const image of images) {
        formData.append('images[]', image);
      }
      formData.append('productName', productName);
      formData.append('price', price);
      formData.append('description', description);
      formData.append('category', category);
      formData.append('deliver', deliver);
      formData.append('stock', stock);
      formData.append('deliveringPrice', deliveringPrice);
      const response = await postWithFile('/products/add', formData);
      navigate(`/product/${response.product.slug}`);
      setLoading(false);
      return;
    } catch (error) {console.log(error);
      if(error && error.response){
        const data = error.response.data;
        setError(data[Object.keys(data)[0]][0] || 'Une erreur est survenue.');
        setLoading(false);
      }
    }
  };

  const autocomplete = (e) => {
    const value = e.target.value;
    const cat = categories;//.reduce((acc, category) => [...acc, ...category.sub_categories], []);
    setCategory(value);
    if (value.length > 0) {
      const filteredCategories = cat.map(category => ({
            ...category,
            sub_categories: category.sub_categories.filter(sub => sub.name.toLowerCase().includes(value.toLowerCase()))
        })).filter(category => category.sub_categories.length > 0);
        setSuggestions(filteredCategories);
    } else {
        setSuggestions([]);
    }
  }

  const handleSuggestionClick = (suggestion) => {
    setCategory(suggestion.name);
    setSuggestions([]);
  };


  const handleImagesChange = (newImages) => {
    setImages(newImages);
  };

  return (
    <div className="bg-gray-100 min-h-screen mt-3">
      <div class="container mx-auto max-w-7xl py-5 mt-6 flex">
        <Sidebar />
        <div class="flex flex-col w-full mr-3 bg-white p-3">
          <div className="w-20 mx-auto items-center">
            <span className="text-center text-orange-500"><ViewfinderCircleIcon /></span>
          </div>
          <h3 className="text-2xl uppercase leading-6 font-semibold text-gray-800 text-center">Ajouter un nouveau produit</h3>
          <form onSubmit={handleSubmit} className="p-6 mt-12 mx-auto w-full">
            {error && (<div className="text-center px-6 py-3 bg-red-100 text-red-900 rounded font-semibold">
              {error}
            </div>)}
            <div className="mt-2 flex items-center">
              <label className="w-1/3 font-semibold text-gray-700">
                Nom du produit
              </label>
              <input
                type="text"
                placeholder="Nom du produit"
                value={productName}
                onChange={e => setProductName(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mt-2 flex items-center">
              <label className="w-1/3 font-semibold text-gray-700">
                Prix
              </label>
              <input
                type="number"
                placeholder="Prix"
                value={price}
                onChange={e => setPrice(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mt-2 flex items-center">
              <label className="w-1/3 font-semibold text-gray-700">
                Stock
              </label>
              <input
                type="number"
                placeholder="Le nombre de produits en stock"
                value={stock}
                onChange={e => setStock(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mt-2 flex items-center">
              <label className="w-1/3 font-semibold text-gray-700">
                Livraison <span className="text-sm">(en nombre de jours)</span>
              </label>
              <input
                type="number"
                placeholder="3 jours"
                value={deliver}
                onChange={e => setDeliver(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mt-2 flex items-center">
              <label className="w-1/3 font-semibold text-gray-700">
                Prix de livraison
              </label>
              <input
                type="number"
                placeholder="Mettez 0 si la livraison est gratuite"
                value={deliveringPrice}
                onChange={e => setDeliveringPrice(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mt-2 flex items-center">
              <label className="w-1/3 font-semibold text-gray-700">
                Description
              </label>
              <ReactQuill theme="snow" value={description} onChange={setDescription} />
              {/* <textarea
                placeholder="Faites une description détaillée du produit"
                value={description}
                onChange={e => setDescription(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              /> */}
            </div>
            <div className="mt-2 flex items-center">
              <label className="w-1/3 font-semibold text-gray-700">
                Catégories
              </label>
              <div className="w-full relative">
                <input
                  type="text"
                  placeholder="Catégorie"
                  value={category}
                  onChange={e => autocomplete(e)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
                {suggestions.length > 0 && (
                  <div className="absolute bg-white w-full max-h-80 overflow-scroll z-10">
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {suggestions.map((suggestion, index) => (
                          <ul style={{ listStyleType: 'none', padding: 0 }} className="p-2">
                          {suggestions.map((category) => (
                              <li key={category.id}>
                                  <strong className="block px-3 pt-3">{category.name}</strong>
                                  <ul style={{ paddingLeft: '20px' }}>
                                      {category.sub_categories.map(sub => (
                                          <li key={sub.id} onClick={() => handleSuggestionClick(sub)} style={{ cursor: 'pointer', padding: '5px', margin: '5px 0' }}>
                                              {sub.name}
                                          </li>
                                      ))}
                                  </ul>
                              </li>
                          ))}
                      </ul>
                      ))}
                  </ul>
                  </div>
              )}
              </div>
            </div>
            <div className="mt-6">
              <DragAndDrop onImagesChange={handleImagesChange} />
              {/* <input
                type="file"
                multiple
                onChange={handleImageChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              /> */}
            </div>
            <div className="mt-4 flex w-full justify-end">
              <button type="submit" className="submit py-2 px-4 text-white rounded text-lg">
                {isLoading ? <div className="flex justify-center items-center"><span className="spinner"></span></div> : 'Ajouter'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
