import React, { useState } from 'react';

const Slider = ({ images }) => {
    const [current, setCurrent] = useState(0);
    const length = images.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    return (
			<div className='relative flex justify-center items-center mx-3 text-sm lg:text-xl'>
				<button onClick={prevSlide} className='absolute left-0 px-2 hover:bg-white hover:bg-opacity-20 lg:px-5 h-full z-10 text-white transition duration-300 ease-in-out'>
						&#10094;
				</button>
				<button onClick={nextSlide} className='absolute right-0 px-2 hover:bg-white hover:bg-opacity-20 lg:px-5 h-full z-10 text-white transition duration-300 ease-in-out'>
						&#10095;
				</button>
				{images.map((img, index) => (
					<div key={index} className={index === current ? 'opacity-100 w-full' : 'opacity-0'}>
						{index === current && (
							<>
								<div className="absolute z-1 left-0 h-full w-full">
									<div className='w-36 lg:w-80 px-10 lg:px-20 pt-4 lg:pt-20 text-white'>
										<div className='text-sm uppercase font-extrabold'>New arrivals</div>
										<div className='font-bold lg:text-6xl text-2xl'>Hot Right Now</div>
										<button className='mt-1 lg:mt-8 bg-white px-6 py-2 text-sm text-red-700 rounded'>Acheter</button>
									</div>
								</div>
								<img src={img} alt='Slide image' className='w-full h-auto block' />
							</>
						)}
					</div>
					))}
			</div>
    );
};

export default Slider;
