import React, { useContext, useState } from "react";
import {post} from '../services/apiService';
import { AuthContext } from "../contexts/AuthContext";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

function Login () {
  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {cartItems, fetchCarts, addToCart} = useContext(AuthContext);
  const {user, setUser} = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials(prevcredentials => ({
      ...prevcredentials,
      [name]: value
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage(''); 
    setIsLoading(true);
    if (!credentials.email || !credentials.password) {
      setErrorMessage('Veuillez remplir tous les champs.');
      setIsLoading(false);
      return;
    }
    try {
      const items = cartItems.map(item => ({
        productId: item.product.id,
        quantity: item.quantity
      }));
      const response = await post('/login', {...credentials, carts: items});
      if (response.access_token && response.authUser) {
        localStorage.removeItem('carts');
        localStorage.setItem("accessToken", response.access_token);
        localStorage.setItem("authUser", JSON.stringify(response.authUser));
        localStorage.setItem('carts', JSON.stringify(response.carts ?? []));
        window.location = '/';
      }else{
        setErrorMessage('Réponse invalide du serveur.');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Une erreur est survenue.';
      setErrorMessage(errorMessage);
    }finally{
      setIsLoading(false);
    }
    return;
  };

  const handleGoogleSuccess = async (response) => {
    setIsLoading(true);
    try {
      const items = cartItems.map(item => ({
        productId: item.product.id,
        quantity: item.quantity
      }));
      const googleResponse = await post('/auth/google/verify', { token: response.credential, carts: items});
      localStorage.setItem("accessToken", googleResponse.access_token);
      localStorage.setItem("authUser", JSON.stringify(googleResponse.authUser));
      localStorage.setItem('carts', JSON.stringify(googleResponse.carts));
      window.location = '/';
    } catch (error) {
      setErrorMessage('Google login failed.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form className="p-12">
        {errorMessage && <div className="text-red-500 font-semibold bg-red-100 px-3 py-2">{errorMessage}</div>}
        
        <label htmlFor="email" className="block text-sm font-medium text-gray-700 py-3">Email</label>
        <input type="email" id="email" name="email" value={credentials.email} onChange={handleChange} className="mt-1 px-6 block w-full border-2 border-gray-300 py-5 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
        
        <label htmlFor="password" className="block text-sm font-medium text-gray-700 py-3 mt-6">Mot de passe</label>
        <input type="password" name="password" id="password" value={credentials.password} onChange={handleChange} className="mt-1 px-6 py-5 block w-full border-2 border-gray-300 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
        
        <div className="mt-6 text-sm flex flex-col lg:flex-row justify-between w-full">
          <label className="text-gray-500 hover:text-black cursor-pointer">
            <input type="checkbox" className="mr-1" />
            <span>Garder la session active ?</span>
          </label>
          <a href="#" className="underline self-end">Mot de passe oublié ?</a>
        </div>
        
        <button type="button" onClick={handleLogin} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded w-full py-4 mt-4 submit font-semibold" disabled={isLoading}>
          {isLoading ? <div className="flex justify-center items-center"><span className="spinner"></span></div> : 'Connexion'}
        </button>
        <GoogleOAuthProvider clientId="59120248455-4elqkri9ha4ogga4j4umaoo5alrerrln.apps.googleusercontent.com">
          <div className="mt-6 flex justify-center space-x-4">
          {isLoading ? (
              <div className="flex justify-center items-center">
                <span className="spinner"></span>
              </div>
            ) : (
            <>
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onFailure={() => setErrorMessage('Google login failed.')}
              cookiePolicy={'single_host_origin'}
            />
            </>)}
          </div>
        </GoogleOAuthProvider>
      </form>
    </>
  );
}

export default Login;
