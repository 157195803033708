import React, { useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

const CheckAnimation = () => {
  return (
    <div className="flex flex-col items-center justify-center my-24 py-24">
      <div className="transition-transform duration-300 transform scale-100 flex flex-col items-center bg-white p-8 rounded-lg">
        <div className="flex items-center justify-center w-32 h-32 rounded-full bg-green-100 mb-4 slide-in">
          <CheckIcon className="h-24 w-24 text-green-500" />
        </div>
        <h2 className="text-3xl font-bold text-gray-800 mb-2 text-center">Merci pour votre commande!</h2>
        <p className="text-gray-600 mb-4 text-center">Merci d'avoir commandé chez nous. Nous espérons que vous apprécierez votre achat.</p>
        <div className="flex items-center">
          <div className="text-gray-600 ml-10 text-center">
            Un email vous a été envoyé avec les détails de votre commande<br/>
            Merci de le conserver
          </div>
        </div>
        <div className="mt-10">
          <Link to="/">Retourner à la page d'accueil</Link>
        </div>
      </div>
    </div>
  );
};

export default CheckAnimation;
