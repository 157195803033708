import React from 'react';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <Helmet>
        <title>Politique de Confidentialité - DeffCi</title>
        <meta name="description" content="Découvrez comment DeffCi protège vos données personnelles et respecte votre vie privée lors de vos achats en ligne au Sénégal." />
        <meta name="keywords" content="Politique de confidentialité, protection des données, vie privée, DeffCi, e-commerce, Sénégal" />
        
        <meta property="og:title" content="Politique de Confidentialité - DeffCi" />
        <meta property="og:description" content="En savoir plus sur la manière dont DeffCi protège vos informations personnelles et assure la sécurité de vos données." />
        <meta property="og:image" content="https://deffci.com/logo.jpg" />
        <meta property="og:url" content="https://deffci.com/politique-de-confidentialite" />
        <meta property="og:type" content="website" />
        
        <meta name="twitter:card" content="https://deffci.com/logo.jpg" />
        <meta name="twitter:title" content="Politique de Confidentialité - DeffCi" />
        <meta name="twitter:description" content="Apprenez comment DeffCi protège vos données personnelles et respecte votre vie privée." />
        <meta name="twitter:image" content="https://deffci.com/logo.jpg" />
      </Helmet>
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-8">
        <h1 className="text-3xl font-bold mb-6">Politique de Confidentialité</h1>
        <p className="mb-4">
          Nous attachons une grande importance à la protection de vos données personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons, et protégeons les informations que vous nous fournissez lorsque vous utilisez notre site.
        </p>

        <h2 className="text-2xl font-semibold mb-4">1. Collecte des Informations</h2>
        <p className="mb-4">
          Nous collectons plusieurs types d'informations lorsque vous utilisez notre site, notamment :
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li className="mb-2">Informations que vous fournissez directement, comme votre nom, adresse, email, et numéro de téléphone lors de la création de votre compte.</li>
          <li className="mb-2">Informations sur vos transactions, telles que les produits achetés et les détails de livraison.</li>
          <li className="mb-2">Données techniques, comme votre adresse IP, le type de navigateur que vous utilisez, et les pages que vous visitez sur notre site.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">2. Utilisation des Informations</h2>
        <p className="mb-4">
          Nous utilisons les informations que nous collectons pour :
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li className="mb-2">Fournir, exploiter et améliorer nos services.</li>
          <li className="mb-2">Traiter vos commandes et gérer vos transactions.</li>
          <li className="mb-2">Communiquer avec vous concernant vos commandes, votre compte ou tout autre aspect de nos services.</li>
          <li className="mb-2">Personnaliser votre expérience utilisateur en vous proposant des produits et services adaptés à vos préférences.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">3. Partage des Informations</h2>
        <p className="mb-4">
          Nous ne vendons pas vos informations personnelles. Cependant, nous pouvons partager vos informations avec des tiers dans les cas suivants :
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li className="mb-2">Avec des partenaires de confiance qui nous aident à exploiter notre site, à mener nos affaires, ou à vous servir, à condition que ces parties conviennent de garder ces informations confidentielles.</li>
          <li className="mb-2">Lorsque nous y sommes obligés par la loi, pour protéger nos droits, ou pour assurer la sécurité de nos utilisateurs.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">4. Sécurité des Données</h2>
        <p className="mb-4">
          Nous mettons en œuvre des mesures de sécurité pour protéger vos informations personnelles. Cependant, aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée, et nous ne pouvons garantir une sécurité absolue.
        </p>

        <h2 className="text-2xl font-semibold mb-4">5. Cookies et Technologies Similaires</h2>
        <p className="mb-4">
          Nous utilisons des cookies pour améliorer votre expérience sur notre site. Les cookies sont de petits fichiers texte qui sont stockés sur votre appareil. Ils nous aident à retenir vos préférences, à comprendre comment vous utilisez notre site, et à personnaliser le contenu en fonction de vos intérêts.
        </p>

        <h2 className="text-2xl font-semibold mb-4">6. Vos Droits</h2>
        <p className="mb-4">
          Vous avez le droit d'accéder, de corriger ou de supprimer vos informations personnelles. Vous pouvez également vous opposer au traitement de vos données ou demander à en restreindre l'utilisation dans certaines circonstances.
        </p>
        <p className="mb-4">
          Pour exercer vos droits, veuillez nous contacter à <a href="mailto:contact@deffci.com" className="text-blue-500 underline">contact@deffci.com</a>.
        </p>

        <h2 className="text-2xl font-semibold mb-4">7. Modifications de cette Politique</h2>
        <p className="mb-4">
          Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page avec une date de mise à jour révisée. Nous vous encourageons à consulter cette politique régulièrement pour rester informé des changements éventuels.
        </p>

        <h2 className="text-2xl font-semibold mb-4">8. Contact</h2>
        <p className="mb-4">
          Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à <a href="mailto:contact@deffci.com" className="text-blue-500 underline">contact@deffci.com</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
