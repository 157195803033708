import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { AuthContext } from '../contexts/AuthContext';
import {
  Bars3Icon,
  BuildingStorefrontIcon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import {DevicePhoneMobileIcon, MagnifyingGlassIcon, ShoppingBagIcon, HomeIcon, HeartIcon, AcademicCapIcon, PuzzlePieceIcon, ShoppingCartIcon, UserGroupIcon, BookOpenIcon, TruckIcon} from '@heroicons/react/24/outline';
import AccountSidebar from './AccountSidebar';
import AccountDropdown from './AccountDropdown';
import CartSidebar from './CartSidebar';
import CategorySidebar from './CategorySidebar';

export default function Header({showSidebarProp, unfixedProp}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isAccountSidebarOpen, setAccountSidebarOpen] = useState(false);
  const [isCartSidebarOpen, setCartSidebarOpen] = useState(false);
  const [isMCOpen, setMCOpen] = useState(false);
  const [query, setQuery] = useState('');

  const { authUser, categories, cartItems, openCart, setOpenCart } = useContext(AuthContext);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setCartSidebarOpen(openCart);
  }, [openCart]);

  useEffect(() => {
    setQuery(searchParams.get('q'));
  }, []);

  const handleSearch = event => {
    event.preventDefault();
    const searchParams = new URLSearchParams();
    searchParams.set('q', query || "");

    navigate(`/search?${searchParams.toString()}`);
  }

  const handleChange = e => {
    setQuery(e.target.value)
  }

  const toggleAccountSidebar = () => {
    setAccountSidebarOpen(!isAccountSidebarOpen);
  };

  const toggleCartSidebar = () => {
    setCartSidebarOpen(!isCartSidebarOpen);
    setOpenCart(!isCartSidebarOpen);
  };

  const handleDataFromChild = () => {
    setMCOpen(false);
  }

  return (
    <header className="bg-white">
      {/* <nav className="mx-auto flex max-w-7xl justify-end">
        <div className='m-3 text-sm text-gray-600 hidden lg:flex'>
          <Link href='#' className='px-3 flex'>
            <span className='pr-1'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
              </svg>

            </span>
            <span>Centre d'aide</span>
          </Link>
          <a href='#' className='px-3 flex items-center'>
            <span className='pr-1'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
              </svg>
            </span>
            <span>Favoris</span>
          </a>
        </div>
      </nav> */}
      <nav className="mx-auto flex flex-col w-full lg:flex-row max-w-7xl items-center justify-between p-4 py-0 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1 justify-between w-full items-center">
          <button
            type="button"
            className="-m-2.5 lg:hidden w-1/5 flex-none  items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMCOpen(!isMCOpen)}
            >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-grow">
            <div className="flex lg:justify-normal justify-center flex-1">
              <Link to="/">
                <img className="lg:w-44 w-36" src="/logo.jpeg" alt="" />
              </Link>
            </div>
          </div>
          <div className="flex-none w-1/5 flex lg:hidden">
            <div className="flex flex-1 justify-end items-center text-sm text-gray-700 font-medium">
              {authUser ? (
                <>
                  <AccountDropdown />
                </>
              ) : (
                <a href="#" className="flex items-center px-5" onClick={toggleAccountSidebar}>
                  <span className='pr-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                    </svg>
                  </span>
                  <span className="hidden lg:flex">Compte</span>
                </a>
              )}
              <a href="#" className="flex items-center relative" onClick={toggleCartSidebar}>
                <span className='pr-1'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                  </svg>
                </span>
                {cartItems.length >0 && (<span className="bg-red-500 text-white absolute -right-2 -top-3 px-2 text-sm rounded-full">{cartItems.length}</span>)}
              </a>
            </div>
          </div>
        </div>
        <form onSubmit={handleSearch} className="flex flex-auto lg:justify-center lg:w-auto w-full">
          <input type='search' name="query" value={query} onChange={handleChange} className="w-full bg-white rounded border border-1 border-gray-100 py-4 shadow-md hover:shadow-lg outline-none text-gray-700 px-3 transition-all duration-500 ease-in-out"
          placeholder="Chercher un produit, une boutique, une marque" />
          <button className="font-black ml-3 text-white border-0 py-2 px-5 focus:outline-none rounded submit">
            <MagnifyingGlassIcon className="w-5 h-5 font-extrabold" />
          </button>
        </form>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center text-sm text-gray-700 font-medium">
          {authUser ? (
            <>
              <AccountDropdown />
            </>
          ) : (
            <a href="#" className="flex items-center px-2" onClick={toggleAccountSidebar}>
              <span className='pr-1'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                </svg>
              </span>
              <span>Compte</span>
            </a>
          )}
          <a href="#" className="flex items-center relative" onClick={toggleCartSidebar}>
            <span className='pr-1'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
              </svg>
            </span>
            {cartItems.length >0 && (<span className="bg-red-500 text-white absolute -right-2 -top-2 px-1 text-sm rounded-full">{cartItems.length}</span>)}
            <span>Panier</span>
          </a>
        </div>
      </nav>
      <div className="hidden lg:flex">
        <CategorySidebar showSidebarProp={showSidebarProp} unfixedProp={unfixedProp} />
      </div>
      <>
        <div className={`fixed top-0 left-0 w-auto h-full lg:hidden bg-white z-30 overflow-y-auto transform ${
          isMCOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'
        } transition-transform duration-300 ease-in-out`}>
          <CategorySidebar showSidebarProp={showSidebarProp} unfixedProp={unfixedProp} sendToParent={handleDataFromChild} />
        </div>
        {isMCOpen && (<div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-20 transform duration-500 ease-in-out" onClick={()=>setMCOpen(!isMCOpen)} />)}
      </>
      
      <div className="fixed flex lg:hidden justify-between bottom-0 bg-white z-50 text-xs shadow w-full p-2 text-center justify-center">
        <Link to="/" className="flex items-center flex-col w-full">
          <HomeIcon className="w-7" />
          <span>Accueil</span>
        </Link>
        <Link to="/search" className="flex items-center flex-col w-full">
          <BuildingStorefrontIcon className="w-7" />
          <span>Boutique</span>
        </Link>
        <Link className="flex items-center flex-col w-full relative" onClick={toggleCartSidebar}>
          <ShoppingCartIcon className="w-7" />
          {cartItems.length >0 && (<span className="bg-black bg-opacity-900 text-white absolute right-5 -top-1 px-2 text-sm rounded-full">{cartItems.length}</span>)}
          <span>Panier</span>
        </Link>
        {/* <Link to="/" className="flex items-center flex-col w-full">
          <HeartIcon className="w-7" />
          <span>Favoris</span>
        </Link> */}
        <Link className="flex items-center flex-col w-full" onClick={toggleAccountSidebar}>
          <UserIcon className="w-7" />
          <span>Compte</span>
        </Link>
      </div>
      <AccountSidebar isOpen={isAccountSidebarOpen} closeSidebar={() => setAccountSidebarOpen(false)} />
      <CartSidebar isOpen={isCartSidebarOpen} closeSidebar={toggleCartSidebar} />
    </header>
  )
}
