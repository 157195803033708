import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className="w-64 h-screen bg-gray-800 text-white lg:flex flex-col hidden">
      <h2 className="text-2xl font-semibold p-4">Tableau de bord</h2>
      <nav className="flex flex-col p-4">
        <Link to="/espace-vendeur/products" className="mb-2 p-2 hover:bg-gray-700 rounded">Produits</Link>
        <Link to="/espace-vendeur/commandes" className="mb-2 p-2 hover:bg-gray-700 rounded">Commandes</Link>
        <a href="#contact-support" className="mb-2 p-2 hover:bg-gray-700 rounded">Contact Support</a>
        <a href="#contact-buyers" className="mb-2 p-2 hover:bg-gray-700 rounded">Contact Buyers</a>
        <a href="#view-accounts" className="mb-2 p-2 hover:bg-gray-700 rounded">View Accounts</a>
      </nav>
    </div>
  );
};

export default Sidebar;
