import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';


function PrivateRoute({ children }) {
  const {authUser} = useContext(AuthContext);
  console.log(authUser)
  const isAuthenticated = authUser !== null;

  return isAuthenticated ? children : <Navigate to="/account" />;
}

export default PrivateRoute;
